export default function Navbar() {
  return (
    <nav id="navbar" className="p-3 w-full bg-white">
      <div className="container mx-auto flex justify-start items-center">
        <div className="flex flex-col justify-center">
          <img
            src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/website/bird-pay.png"
            alt="BirdPay Logo"
            className="w-16 mr-3 desaturate"
            loading="lazy"
          />
          <p className="mt-2 w-full text-center">Express</p>
        </div>
      </div>
    </nav>
  )
}
