import React, { ErrorInfo, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode // Optional fallback UI
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: undefined, errorInfo: undefined }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch and log the error and error information
    this.setState({ error, errorInfo })
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  render() {
    const { hasError, error, errorInfo } = this.state

    if (hasError) {
      return (
        <div
          style={{ padding: '20px', backgroundColor: '#fdd', color: '#333' }}
        >
          <h1>Something went wrong.</h1>
          <h2>Error: {error?.toString()}</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error?.stack}
            <br />
            {errorInfo?.componentStack}
          </details>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
