import React from 'react'

import { Navbar } from './Components'

export default function App() {
  return (
    <main className="container mx-auto pb-20">
      <Navbar />

      <p className="mt-5 text-xl text-gray-700 text-center pt-20">
        App not installed
      </p>

      <div className="flex justify-center">
        <a
          className="py-3 px-8 mt-10 text-sm text-primary bg-primary hover:underline hover:bg-primary-active rounded-full inline-block font-semibold"
          href="mailto:birdwallet.testflight@gmail.com?subject=Please%20add%20me%20to%20Testflight"
        >
          Download App
        </a>
      </div>
    </main>
  )
}

/*
     (function () {
        try {
          var prefersDark = window.matchMedia(
            "(prefers-color-scheme: dark)"
          ).matches;
          if (!prefersDark) return;
          var favicon = document.getElementById("favicon");
          if (favicon)
            favicon.setAttribute(
              "href",
              "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_459_963)'%3E%3Cpath d='M11.4327 1.00388C9.64526 0.919753 8.14218 2.21231 7.88574 3.91533C7.87559 3.99436 7.86035 4.07085 7.84766 4.14733C7.44904 6.26845 5.59303 7.87459 3.3638 7.87459C2.5691 7.87459 1.82263 7.67064 1.17265 7.31372C1.09394 7.27038 1 7.32647 1 7.4157V7.87204V14.7479H7.84512V9.59291C7.84512 8.64452 8.61189 7.87459 9.5564 7.87459H11.2677C13.2049 7.87459 14.7639 6.2608 14.6877 4.29774C14.6191 2.53099 13.1922 1.08802 11.4327 1.00388Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_459_963'%3E%3Crect width='14' height='14' fill='white' transform='translate(1 1)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"
            );
        } catch (e) {}
      })();
      */

// https://pay.birdwallet.xyz/order/ordr_i5UTCZhHCQFsi7iTyrZMbB
