import { useEffect, useRef, useState } from 'react'

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback if it changes
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay)

      // Clear the interval on unmount or if the delay changes
      return () => clearInterval(intervalId)
    }
  }, [delay])
}
