import React from 'react'
import { OrderStatus as OrderStatusEnum } from '../Types'

interface Props {
  status: OrderStatusEnum
}

// Define Tailwind CSS classes for each status
const statusStyles = {
  [OrderStatusEnum.CREATED]: 'bg-gray-300 text-gray-800',
  [OrderStatusEnum.PAYER_ACTION_REQUIRED]: 'bg-yellow-300 text-yellow-800',
  [OrderStatusEnum.APPROVED]: 'bg-green-300 text-green-800',
  [OrderStatusEnum.CAPTURE_INITIALIZED]: 'bg-blue-300 text-blue-800',
  [OrderStatusEnum.CAPTURE_PENDING]: 'bg-blue-400 text-blue-900',
  [OrderStatusEnum.CAPTURED]: 'bg-green-400 text-green-900',
  [OrderStatusEnum.SETTLEMENT_PENDING]: 'bg-orange-300 text-orange-800',
  [OrderStatusEnum.SETTLED]: 'bg-teal-300 text-teal-800',
  [OrderStatusEnum.FAILED]: 'bg-red-300 text-red-800',
  [OrderStatusEnum.EXPIRED]: 'bg-gray-500 text-gray-100',
  [OrderStatusEnum.CANCELED]: 'bg-gray-700 text-gray-100',
  [OrderStatusEnum.REFUND_PENDING]: 'bg-purple-300 text-purple-800',
  [OrderStatusEnum.REFUNDED]: 'bg-purple-400 text-purple-900',
}

export default function OrderStatus({ status }: Props) {
  return (
    <div
      className={`px-3 py-1 rounded-full text-sm font-semibold ${statusStyles[status]}`}
    >
      {status}
    </div>
  )
}
