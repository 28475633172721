export interface Total {
  value: string
  currency: string
  status: string
}

export enum OrderStatus {
  CREATED = 'created',
  PAYER_ACTION_REQUIRED = 'payer_action_required',
  APPROVED = 'approved',
  CAPTURE_INITIALIZED = 'capture_initialized',
  CAPTURE_PENDING = 'capture_pending',
  CAPTURED = 'captured',
  SETTLEMENT_PENDING = 'settlement_pending',
  SETTLED = 'settled',
  FAILED = 'failed',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  REFUND_PENDING = 'refund_pending',
  REFUNDED = 'refunded',
}

export enum OrderType {
  ONE_TIME = 'one_time',
  RECURRING = 'recurring',
}

export interface LineItem {
  label: string
  type: 'item' | 'tax' | 'shipping'
  value: string
  sku_id: string | null
  status: string
  payment_timing: string | null
  recurring_payment_start_date: string | null
}

export type ShippingFields = 'postalAddress' | 'name'
export type BillingFields = 'phone' | 'email'

export interface ShippingMethod {
  label: string
  amount: string
  detail: string
  identifier: string
  selected: boolean
}

export interface MerchantAddress {
  line1: string | null
  line2: string | null
  city: string | null
  state: string | null
  postal_code: string | null
  country: string | null
}

export interface Merchant {
  name: string
  logo: string
  brand_color: string
  address: string
  public_key_pem: string | null
  business_address: MerchantAddress
  email: string
  phone: string
  url: string
  balances: string | null
}

export interface CheckoutSession {
  id: string
  created_at: string
  updated_at: string
  status: OrderStatus
  type: OrderType
  total: Total
  line_items: LineItem[]
  required_shipping_fields: ShippingFields[]
  required_billing_fields: BillingFields[]
  shipping_address: string | null
  shipping_methods: ShippingMethod[]
  merchant: Merchant
  success_url: string | null
  cancel_url: string | null
}
