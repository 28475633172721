import React from 'react'

export enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface NotificationInfo {
  message: string
  type: NotificationType
}

interface NotificationProps {
  notification: NotificationInfo
  onClose: () => void
}

export default function Notification({
  notification,
  onClose,
}: NotificationProps) {
  // Define styles for different notification types
  const typeStyles = {
    success: 'bg-green-100 text-green-700 border-green-500',
    error: 'bg-red-100 text-red-700 border-red-500',
    warning: 'bg-yellow-100 text-yellow-700 border-yellow-500',
    info: 'bg-blue-100 text-blue-700 border-blue-500',
  }

  return (
    <div
      className={`absolute top-2 left-1/2 transform -translate-x-1/2 p-4 border-l-4 rounded w-full shadow-lg max-w-4xl ${
        typeStyles[notification.type]
      }`}
    >
      <div className="flex items-center justify-between">
        <span>{notification.message}</span>
        <button
          onClick={onClose}
          className="ml-4 text-lg font-bold focus:outline-none"
        >
          &times;
        </button>
      </div>
    </div>
  )
}
